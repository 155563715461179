<template>
  <v-container>
    <v-skeleton-loader
      v-if="!loaded"
      type="article,article, actions"
    ></v-skeleton-loader>
    <v-alert
      v-if="$store.state.balance <= 0 && loaded"
      type="warning"
      class="text-center"
      dense
    >
      <small
        >رصيد حسابك صفر لا يمكنك اضافة اى شهادة برجاء اضافة رصيد حتى تتمكن من
        اضافة شهادات جديدة</small
      >
    </v-alert>

    <v-alert
      v-if="$store.state.block == 1 && loaded"
      type="error"
      dense
      class="text-center"
    >
      <small> لا يمكنك اضافة شهادات تم حجبك من قبل الأدارة</small>
    </v-alert>

    <v-card
      v-if="$store.state.balance > 0 && $store.state.block == 0 && loaded"
      max-width="700"
      class="my-5 mt-sm-10 mx-auto"
    >
      <v-card-title> اضافة شهادة جديدة </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-form ref="form">
          <v-alert v-if="no_months" type="error" dense class="text-center">
            <small> نأسف الأستثمار متوقف حاليا</small>
          </v-alert>

          <v-alert v-if="loaded" type="warning" dense class="text-center">
            <small>
              يرجى العلم انه لايوجد اى طريقة لألغاء الشهادة بعد تنفيذ
              الطلب</small
            >
          </v-alert>
          <v-alert v-if="alert" type="error" dense class="text-center">
            <small>لا يوجد رصيد كافى لأضافة الشهادة</small>
          </v-alert>

          <v-select
            :rules="select_rules"
            label="اختيار المدة"
            :items="items"
            item-text="text"
            item-value="id"
            v-model="certificate"
            return-object
            :disabled="no_months"
          ></v-select>

          <v-text-field
            :rules="amount_rules"
            type="number"
            @input="amount_changed($event)"
            v-model="amount"
            label="قيمة الشهادة"
            class="mb-3"
            :disabled="no_months"
          ></v-text-field>

          <v-text-field
            dense
            hide-details
            class="mb-2"
            filled
            label="تفنيط المبلغ"
            disabled
            v-model="input_amount"
          >
          </v-text-field>

          <v-text-field
            dense
            hide-details
            class="mb-2"
            filled
            label="الربح الشهرى"
            disabled
            v-model="month_profit"
          >
          </v-text-field>

          <v-text-field
            dense
            class="mb-4"
            hide-details
            filled
            label="الربح الأجمالى"
            disabled
            v-model="total_profit"
          >
          </v-text-field>
          <v-btn @click="add_certificate()" color="success"
            >اضافة الشهادة</v-btn
          >
        </v-form>
      </v-card-text>
    </v-card>

    <v-dialog v-model="dialog" max-width="500">
      <v-card>
        <v-card-title>تأكيد اضافة الشهادة</v-card-title>
        <v-card-text>
          <v-alert type="info" dense class="mb-3"
            >تنويه لايمكن الغاء الشهادة بعد اضافتها وشكرا ...</v-alert
          >
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn
            :disabled="loading_save"
            :loading="loading_save"
            @click="save_certificate()"
            color="primary"
            >حفظ الشهادة</v-btn
          >
          <v-btn @click="dialog = false" text color="error">الغاء</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog hide-overlay max-width="500" v-model="success_dialog">
      <v-card>
        <v-card-text class="pt-5">
          <v-alert
            type="success"
            class="text-center white--text mt-5 align-center"
            >مبروك تم اضافة الشهادة بنجاح
          </v-alert>

          <LottieAnimation
            ref="anim"
            :animationData="require('@/assets/lf30_editor_qofqgkkj.json')"
            :loop="true"
            style="width: 100%; margin: 50px auto"
          ></LottieAnimation>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="primary" @click="success_dialog = false">تم</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import LottieAnimation from "lottie-web-vue";
export default {
  name: "certificate",
  data() {
    return {
      loaded: false,
      blocked: this.$store.state.user_data.block == 0 ? false : true,
      alert: false,
      no_months: false,
      success_dialog: false,
      dialog: false,
      loading_save: false,
      items: [],
      amount: 0,
      input_amount: 0,
      select_rules: [
        (v) => Boolean(Object.keys(v || {})[0]) || "يجب اختيار الشهر",
      ],
      amount_rules: [(v) => !!v || "يجب اختيار الشهر"],
      certificate: {},
    };
  },
  components: {
    LottieAnimation,
  },
  watch: {
    success_dialog(newVal, oldVal) {
      if (!newVal && oldVal) {
        this.$router.push("/Dashboard");
      }
    },
  },
  computed: {
    total_profit() {
      let total = (this.certificate.percent / 100) * this.amount;
      return total ? total : 0;
    },
    month_profit() {
      let total = (this.certificate.percent / 100) * this.amount;
      let total_month = total / parseInt(this.certificate.months);
      return isNaN(Math.ceil(total / parseInt(this.certificate.months)))
        ? 0
        : Intl.NumberFormat("en-US").format(total_month.toFixed(2));
    },
  },
  methods: {
    get_certificates_settings() {
      this.$store
        .dispatch("public__request", {
          config: {
            url: "select_certificate",
            method: "get",
          },
        })
        .then((res) => {
          let items = [];
   
          res.data.data.forEach((ev, i) => {

            items.push({
              months: ev.months,
              text: `${ev.months} شهر`,
              id: ev.id,
              percent: ev.percent,
            });
          });
          this.items = items;
                   this.loaded = true;
          if (items.length == 0) {
            this.no_months = true;
          } else {
            this.no_months = false;
          }
        })
        .catch((err) => {
          // console.log(err.response);
        });
    },
    amount_changed(ev) {
      let val = parseInt(ev);
      // this.amount = val;
      val
        ? (this.input_amount = Intl.NumberFormat("en-US").format(
            val.toFixed(4)
          ))
        : (this.input_amount = 0);
    },
    add_certificate() {
      if (this.$refs.form.validate()) {
        // console.log(this.$store.state.balance <= parseInt(this.amount));
        if (
          this.$store.state.balance >= parseInt(this.amount) &&
          this.$store.state.balance != 0
        ) {
          this.dialog = true;
          this.alert = false;
        } else {
          this.alert = true;
          this.dialog = false;
        }
      }
    },
    save_certificate() {
      if (
        this.$refs.form.validate() &&
        this.certificate.id &&
        !this.no_months &&
        !this.blocked
      ) {
        this.loading_save = true;
        this.$store
          .dispatch("public__request", {
            config: {
              url: "certificates",
              method: "post",
            },
            data: {
              certificate_id: this.certificate.id,
              percent: this.certificate.percent,
              amount: this.amount,
            },
          })
          .then((res) => {
            // console.log(res);
            this.success_dialog = true;
            this.dialog = false;
            this.loading_save = false;
          })
          .catch((err) => {
            // console.log(err.response);
            this.success_dialog = false;
            this.loading_save = false;
            if (err.response.data.data == "cant add certificate") {
              this.$store.state.block = 1;
              this.dialog = false;
            }
          });
      }
    },
  },
  mounted() {
    this.get_certificates_settings();
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .theme--light.v-label--is-disabled,
  .theme--light.v-input--is-disabled input {
    color: #000;
  }
}
</style>
